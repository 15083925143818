@import '../variables.scss';

.metaPeople {

    .hero {
        height: 70vh;
        .bgVideo {
            object-fit: cover;
            width: 100vw;
            height: 70vh;
            position: absolute;
            top: 0;
            left: 0;
        }
        .overlay {
            //background-color: rgba(255, 255, 255, 0.1);
            width: 100%;
            height: 100%;
            position: absolute;
            .heroContainer {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 100%;
                padding: 40px 40px;
                // position: absolute;
                // left: 50%;
                // top: 0%;
                // transform: translate(-50%, 0%);
                .title {
                    // font-size: 7em;
                    // font-weight: bolder;
                    // color: $Black;
                    // text-align: center;
                    transition: all 0.5s;
                    font-variation-settings: "wght" 800;
                    font-size: 7rem;
                    text-align: center;
                    color: $Black;
                    &:hover {
                        font-variation-settings: "wght" 900;
                    }
                }
                .desc {
                    text-align: center;
                    font-size: 1.5em;
                    font-weight: 400;
                    color: $Black;
                }
            }
        }
    }

    .collection {
        .collectionContainer {
            padding-top: 50px;
            .nftContainer {
                margin: 2px;
                transition: transform .5s; /* Animation */
                min-width: 400px;
                height: 450px;
                padding: 0;
                &:hover {
                    transform: scale(1.1);
                    cursor: pointer;
                }
                .badgeContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: flex-end;
                    padding: 40px;
                    height: 100%;
                    width: 100%;
                    background-color: rgba(255,255,255,0.7);
                    .badge {
                        background: $Black;
                        color: $White;
                        font-size: $Small;
                        font-weight: 500;
                        padding: 5px 15px;
                        border-radius: 12px;
                        .sold {
                            margin-bottom: 3px;
                            font-size: $Medium;
                            color: $Green;
                        }
                    }
                }
            }
            .filtersContainer {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 4px;
                padding-bottom: 25px;
                flex-wrap: wrap;
                .filterTitle {
                    background-color: $White;
                    color: $DarkGrey;
                    border: none;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
                    margin-right: 8px;
                    transition: box-shadow 0.4s ease-in-out;
                    border-color: $White;
                    border: 1px solid $White;
                    &:hover {
                        cursor: pointer;
                        border-color: $Blue;
                        background-color: $Gradient;
                        box-shadow: none;
                        transition: box-shadow 0.4s ease-in-out;
                    }
                }
                .filterMenu {
                    border-radius: 20px;
                    border-width: 1px;
                    border-color: $White;
                    background-color: $White;
                    color: $DarkGrey;
                    padding: 10px;
                    margin-top: 10px;
                    transition: box-shadow 0.4s ease-in-out;
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
                    .filterMenuItem {
                        background-color: $Gradient;
                        &:hover {
                            cursor: pointer;
                            background-color: $LightGrey;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .metaPeople {
        .distribution {
            .distributionContainer {
                .chartContainer {
                    .chartItem {
                        font-size: $xSmall;
                        font-weight: 400;
                        color: transparent;
                    }
                }
            }
        }
        .hero {
            .overlay {
                .heroContainer {
                    padding: 12px 12px;
                    .pageInfoContainer {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                    }
                }
            }
        }
    }
}
