@import '../variables.scss';

.roadmap {
    .heroMini {
        background-image: url('./../assets/images/roadmapbg.jpeg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .overlay {
            background-color: rgba(0, 0, 0, 0.4);
            padding: 60px 0px;
            .content {
                margin-top: 30%;
                color: $White;
                .title {
                    font-size: $xxLarge;
                    font-weight: 900;
                }
                .desc {
                    font-size: $Medium;
                    font-weight: 600;
                    .light {
                        font-weight: 400;
                    }
                    .thick {
                        font-weight: 800;
                    }
                }
            }
        }
    }

    .timeline {
        background-color: $ExtraDarkGrey;
        .timelineContainer {
            padding: 120px 0px;
            .title {
                font-size: $xxLarge;
                font-weight: 700;
                color: $White;
                text-align: center;
                .light {
                    font-weight: 400;
                }
                .thick {
                    font-weight: 900;
                }
            }
            .quarterListContainer {
                margin-top: 100px;
                .quarterContainer {
                    margin-top: 40px;
                    .title {
                        font-size: $Large;
                        font-weight: 400;
                        text-align: left;
                    }
                    .cardContainer {
                        background-size: cover;
                        background-position: center;
                        border-radius: 20px;
                        padding: 20px 20px;
                    }
                    .outerContainer {
                        background-color: $BaseLayerBg;
                        backdrop-filter: $BaseLayerFilter;
                        box-shadow: $Elevation-10;
                        padding: 20px 20px;
                        border-radius: 12px;
                        .listItem {
                            background-color: $BaseLayerBg;
                            backdrop-filter: $BaseLayerFilter;
                            box-shadow: $Elevation-10;
                            padding: 20px 20px;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            border-radius: 8px;
                            margin-bottom: 8px;
                            .status {
                                height: 24px;
                                width: 24px;
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: contain;
                                margin-right: 10px;
                            }
                            .task {
                                font-weight: 500;
                                color: $Black;
                            }
                            &:last-child {
                                margin-bottom: 0px;
                            }
                            &.done {
                                background-color: $CommandLayerBg;
                                backdrop-filter: $CommandLayerFilter;
                                .task {
                                    font-weight: 600;
                                }
                                .status {
                                    background-image: url('./../assets/images/done.svg');
                                }
                            }
                            &.pending {
                                .status {
                                    background-image: url('./../assets/images/pending.svg');
                                }
                            }
                        }
                    }
                    &:first-child {
                        .title {
                            font-weight: 900;
                        }
                        .cardContainer {
                            background-image: url('./../assets/images/q1.jpeg');
                        }
                    }
                    &:nth-child(2) {
                        .cardContainer {
                            background-image: url('./../assets/images/q2.jpeg');
                        }
                    }
                    &:nth-child(3) {
                        .cardContainer {
                            background-image: url('./../assets/images/q3.jpeg');
                        }
                    }
                    &:nth-child(4) {
                        .cardContainer {
                            background-image: url('./../assets/images/q4.jpeg');
                        }
                    }
                    &:nth-child(5) {
                        .cardContainer {
                            background-image: url('./../assets/images/q1.jpeg');
                        }
                    }
                    &:nth-child(6) {
                        .cardContainer {
                            background-image: url('./../assets/images/q2.jpeg');
                        }
                    }
                }
            }
            
        }
    }
}

@media only screen and (max-width: 991px) {
    .roadmap {
        .heroMini {
            .overlay {
                background-color: rgba(0, 0, 0, 0.4);
                padding: 12px 12px;
                .heroMiniContainer {
                    min-height: 400px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .content {
                        margin-top: auto;
                    }
                }
            }
        }
    
        .timeline {
            .timelineContainer {
                padding: 60px 12px;
                .quarterListContainer {
                    margin-top: 60px;
                    .quarterContainer {
                        padding: 12px 12px;
                        .cardContainer {
                            margin-top: 20px;
                            padding: 12px 12px;
                        }
                        .outerContainer {
                            padding: 12px 12px;
                        }
                    }
                }
                
            }
        }
    }
}