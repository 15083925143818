@import '../variables.scss';

.about {
    .heroMini {
        background-image: url('./../assets/images/aboutbg.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .overlay {
            background-color: rgba(0, 0, 0, 0.4);
            padding: 60px 0px;
            .content {
                margin-top: 30%;
                color: $White;
                .title {
                    font-size: $xxLarge;
                    font-weight: 900;
                }
                .desc {
                    font-size: $Medium;
                    font-weight: 600;
                    .light {
                        font-weight: 400;
                    }
                    .thick {
                        font-weight: 800;
                    }
                }
            }
        }
    }

    .mission {
        background: $LightGradient;
        .missionContainer {
            padding: 120px 20px;
            text-align: center;
            .title {
                font-size: $Medium;
                font-weight: 800;
                color: $Black;
            }
            .desc {
                font-size: $Small;
                font-weight: 400;
                color: $Grey;
                margin-top: 20px;
            }
        }
    }

    .contact {
        background-color: $White;
        .contactContainer {
            padding: 120px 20px;
            text-align: center;
            .title {
                font-size: $xLarge;
                font-weight: 200;
                color: $Black;
            }
            .desc {
                font-size: $Medium;
                font-weight: 200;
                color: $Grey;
            }
        }
    }

    .team {
        padding: 100px 20px;
        background-image: url('./../assets/images/q1.jpeg');
        background-size: cover;
        .teamContainer {
            .title {
                color: $Black;
                font-size: $Large;
                font-weight: 900;
                margin-bottom: 40px;
                text-align: center;
            }
            .tileOuter {
                margin-top: 20px;
            }
            .teamListContainer {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
            }
            .tile {
                background-color: $BaseLayerBg;
                backdrop-filter: $BaseLayerFilter;
                box-shadow: $Elevation-10;
                border-radius: 12px;
                margin: 5px;
                padding: 20px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                text-align: center;
                img {
                    border-radius: 8px;
                    box-shadow: $Elevation-10;
                }
                .content {
                    text-align: left;
                    margin-left: 20px;
                    .name {
                        font-size: $Small;
                        color: $Black;
                        font-weight: 600;
                        margin-top: 20px;
                    }
                    .role {
                        color: $DarkGrey;
                        font-size: $xSmall;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .about {
        .heroMini {
            .overlay {
                padding: 12px 12px;
                .heroMiniContainer {
                    height: 400px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
        }
    
        .contact {
            .contactContainer {
                .title {
                    font-size: $Medium;
                    font-weight: 400;
                }
            }
        }
    
        .team {
            .teamContainer {
                .tile {
                    width: 100%;
                }
            }
        }
    }
}