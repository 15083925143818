@import '../../variables.scss';

.landingPage {
    background-image: url('../../assets/images/aboutbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
    .overlay {
        background-color: $BaseLayerBg;
        backdrop-filter: $BaseLayerFilter;
        padding: 40px 0px 0px 0px;
        min-height: 100vh;
        .landingPageContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            height: 100%;
            .content {
                padding: 15em 0px 0px 0px;
                .title {
                    font-size: 6em;
                    font-weight: 400;
                    color: $Black;
                    .thick {
                        font-weight: 800;
                    }
                }
                .desc {
                    font-size: $Small;
                    font-weight: 400;
                    color: $Black;
                    margin-top: 4px;
                    letter-spacing: 0.4em;
                    word-spacing: 0.6em;
                    text-transform: uppercase;
                    margin-bottom: 40px;
                }
                .preview {
                    margin-top: 200px;
                    .previewImage {
                        box-shadow: $Elevation-10;
                        border-radius: 12px;
                    }
                }
            }

            .connection {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: $CommandLayerBg;
                backdrop-filter: $CommandLayerFilter;
                border-radius: 12px;
                width: 100%;
                height: calc(100vh - 200px);
                margin-top: 40px;
                .statusBlink {
                    font-size: $Small;
                    font-weight: 600;
                    color: $Black;
                    margin-top: 12px;
                    letter-spacing: 0.4em;
                    text-transform: uppercase;
                    // Make the text blink in a loop
                    animation: blink 1s linear infinite;
                    @keyframes blink {
                        0% {
                            opacity: 1;
                          }
                          50% {
                            opacity: 0;
                          }
                          100% {
                            opacity: 1;
                          }
                    }
                }
                .status {
                    font-size: $Small;
                    font-weight: 600;
                    color: $Black;
                    margin-top: 12px;
                    .address {
                        font-size: $Small;
                        font-weight: 400;
                        color: $Blue;
                        margin-top: 4px;
                    }
                }
                .desc {
                    font-size: $xSmall;
                    font-weight: 400;
                    color: $LightGrey;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
}