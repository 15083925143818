@import '../../variables.scss';

.portalHome {
    margin-top: 40px;
    padding: 0;
    .contentRow {
        padding: 0;
    }
    
    .baseTileContainer {
        background-color: $BaseLayerBg;
        backdrop-filter: $BaseLayerFilter;
        border-radius: 12px;
        padding: 40px 40px;
        margin-bottom: 20px;
        margin-top: 20px;

        .commandTileContainer {
            background-color: $CommandLayerBg;
            backdrop-filter: $CommandLayerFilter;
            border-radius: 8px;
            padding: 24px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            box-shadow: $Elevation-5;
            height: 100%;
            text-align: left;
        }
    }

    .districtsRow > * {
        padding: 16px;
    }

    .ava {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 700px;
        .avaProfilePictureContainer {
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 40px;
            .outerBorder {
                background: $MediumGradient;
                border-radius: 50%;
                padding: 12px;
                animation: pulse 2s infinite;
                @keyframes pulse {
                    0% {
                        padding: 4px
                    }
                    50% {
                        padding: 12px;
                    }
                    100% {
                        padding: 4px;
                    }
                  }
                .picture {
                    height: 96px;
                    width: 96px;
                    background-image: url('./../../assets/images/ava.gif');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    border-radius: 50%;
                }
            }
        }
        .chatContainer {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            max-height: 500px;
            overflow-x: hidden;
            overflow-y: scroll;   
            padding-right: 17px; /* width of the scrollbar */                 
            .chatItem {
                background-color: $White;
                font-size: $Small;
                color: $LightGrey;
                border-radius: 8px;
                padding: 12px 16px;
                width: fit-content;
                margin-bottom: 8px;
                box-shadow: $Elevation-5;
                font-family: 'Roboto Mono', monospace;
                &.avaMessage {
                    text-align: left;
                    align-self: flex-start;
                }
                &.userMessage {
                    text-align: right;
                    align-self: flex-end;
                    background: $Gradient;
                    color: $Black;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
        }

        .chatInputContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            .chatInput {
                border: none;
                padding: 12px 16px;
                box-shadow: $Elevation-5;
                font-size: $xSmall;
            }
            .chatButton {
                margin-left: 12px;
                padding: 4px 12px;
                box-shadow: $Elevation-5;
                font-size: $Medium;
                color: $White;
                &:hover {
                    background: $Gradient;
                    color: $Black;
                }
            }
        }
    }

    .accountBalances {
        .label {
            font-size: $xSmall;
            font-weight: 400;
            color: $LightGrey;
        }
        .dataContainer {
            margin-top: 24px;
            width: 100%;
            .item {
                border-bottom: 1px solid $ExtraLightGrey;
                padding: 12px 0px;
                .value {
                    font-size: $Small;
                    font-weight: 500;
                    color: $Grey;
                }
                .usdValue {
                    font-size: $xSmall;
                    font-weight: 300;
                    color: $LightGrey;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .metaPeople {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        a {
            background-color: $Black;
            color: $White;
            padding: 8px 12px;
            border-radius: 4px;
            text-decoration: none;
            &:hover {
                color: $Orange;
            }
        }
        .textContent {
            flex: 1;
            .title {
                font-size: $Medium;
                font-weight: 600;
                color: $Grey;
                margin-bottom: 12px;
            }
            .desc {
                font-size: $xSmall;
                font-weight: 400;
                color: $LightGrey;
                flex: 1;
            }
        }
    }

    .district {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .iconContainer {
            text-align: right;
            width: 100%;
        }
        .title {
            font-size: $Small;
            font-weight: 400;
            color: $Grey;
            margin-top: 40px;
        }
        .desc {
            font-size: $xSmall;
            font-weight: 400;
            color: $LightGrey;
        }
        .tag {
            background: $Gradient;
            color: $Black;
            font-size: $xxSmall;
            padding: 2px 12px;
            border-radius: 24px;
            margin-top: 12px;
        }
        &:hover {
            cursor: pointer;
            box-shadow: none;
            animation: pulse 0.8s infinite;
            @keyframes pulse {
                0% {
                    box-shadow: $Elevation-5;
                }
                100% {
                    box-shadow: 0;
                }
            }
            .title {
                font-weight: 600;
            }
        }
    }
    .usernameModal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    background-color: red;
                }
            }
        }
    }
    .modal {
        .modal-dialog {
            &.usernameModal { 
                .modal-content {
                    .modal-body {
                        background-color: red;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
}