@import '../variables.scss';

.home {
    .hero {
        height: 100vh;
        .bgVideo {
            object-fit: cover;
            width: 100vw;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
        }
        .overlay {
            //background-color: rgba(255, 255, 255, 0.2);
            //background-color: rgba(0, 0, 0, 0.2);
            width: 100%;
            height: 100%;
            position: absolute;
            .heroContainer {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 100%;
                padding: 40px 40px;
                // position: absolute;
                // left: 50%;
                // top: 0%;
                // transform: translate(-50%, 0%);
                .highlightContainer {
                    // background-color: $BaseLayerBg;
                    // backdrop-filter: $BaseLayerFilter;
                    //padding: 30px 40px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    a {
                        text-decoration: none;
                        color: $Black;
                    }
                    .aiTile {
                        //background: linear-gradient(106deg, #FFF 0.45%, #D0EDFF 30.11%, #E2D6FE 68.98%, #FED6FF 98.64%);
                        background-color: $ContentLayerBg;
                        border-radius: 12px;
                        padding: 12px 20px;
                        margin-top: 20px;
                        width: fit-content;                        
                        transition: all 0.5s;
                        &:hover {
                            background-color: $ExtraDarkGrey;
                            color: $White;
                            box-shadow: $Elevation-10;
                            padding: 12px 40px;
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }
                    .title {
                        // font-size: 7em;
                        // font-weight: bolder;
                        // color: $Black;
                        // text-align: center;
                        transition: all 0.5s;
                        font-variation-settings: "wght" 800;
                        font-size: 7rem;
                        text-align: center;
                        padding: 10px 40px;
                        display: inline-block;
                        //background: linear-gradient(106deg, rgba(255, 255, 255) 0.45%, rgba(208, 237, 255) 30.11%, rgba(226, 214, 254) 68.98%, rgba(254, 214, 255) 98.64%);
                        //background: linear-gradient(106deg, rgba(255, 255, 255, 0.8) 0.45%, rgba(208, 237, 255, 0.8) 30.11%, rgba(226, 214, 254, 0.8) 68.98%, rgba(254, 214, 255, 0.8) 98.64%);
                        border-radius: 12px;
                        margin-bottom: 12px;
                        color: $Black;
                        .gradientText {
                            background: linear-gradient(106deg, #26b9b2 0.45%, #6d8cf1 30.11%, #6e3bce 68.98%, #FF33FF 98.64%);
                            -webkit-background-clip: text;
                            color: #FFB3FF; /* Update the color to a stronger shade */
                            background-clip: text;
                            color: transparent;
                            -webkit-text-fill-color: transparent; /* for Webkit browsers */
                            display: inline-block; /* Necessary to apply the gradient to text */
                            text-align: center; /* Center the text */
                        }
                        &:hover {
                            font-variation-settings: "wght" 900;
                            margin-bottom: 4px;
                        }
                    }
                    .desc {
                        text-align: center;
                        font-size: 0.85em;
                        text-transform: uppercase;
                        font-weight: 600;
                        color: $Black;
                        letter-spacing: 3px;
                        background: linear-gradient(106deg, #FFF 0.45%, #D0EDFF 30.11%, #E2D6FE 68.98%, #FED6FF 98.64%);
                        padding: 10px 20px;
                        border-radius: 12px;
                    }
                    .headerButtons {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        .aiTile {
                            &:first-child {
                                margin-right: 20px;
                            }
                        }
                    }
                }
                .featureContainer {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    .featureTile {
                        background-color: $BaseLayerBg;
                        backdrop-filter: $BaseLayerFilter;
                        box-shadow: $Elevation-10;
                        padding: 12px 16px;
                        border-radius: 12px;
                        height: 100%;
                        flex: 1;
                        margin-right: 10px;
                        &:last-child {
                            margin-right: 0;
                        }
                        .title {
                            font-size: $Small;
                            font-weight: bold;
                            color: $Black;
                        }
                        .desc {
                            font-size: $xSmall;
                            color: $Black;
                        }
                    }
                }
            }
        }
    }

    .newTokenMigration {
        background: $ExtraDarkGrey;
        padding: 120px 60px;
        .newTokenMigrationContainer {
            text-align: center;
            .title {
                font-size: $Large;
                font-weight: 800;
                color: $White;
            }
            .desc {
                font-size: $Small;
                font-weight: 400;
                color: $ExtraLightGrey;
                .highlight {
                    font-weight: 600;
                }
            }
            .buttonsContainer {
                margin-top: 40px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                :first-child {
                    margin-right: 20px;
                }
            }
        }
    }

    .odyssey {
        background: $LightGradient;
        padding: 120px 60px;
        .odysseyContainer {
            height: 900px;
            .content {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-image: url('./../assets/images/districts.svg');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                .title {
                    font-size: 5em;
                    font-weight: 900;
                    color: $Black;
                    text-align: center;
                    background-image: url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif);
                    background-size: cover;
                    color: transparent;
                    -moz-background-clip: text;
                    -webkit-background-clip: text;
                }
                .desc {
                    font-size: $Small;
                    font-weight: 500;
                    color: $LightGrey;
                    text-align: center;
                    margin-bottom: 16px;
                }
            }
        }
        .downloadContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            border-bottom: 1px solid $ExtraLightGrey;
            margin-top: 40px;
            .textContainer {
                padding: 20px;
                .title {
                    font-size: $Large;
                    font-weight: 800;
                    color: $Black;
                }
                .desc {
                    font-size: $Small;
                    font-weight: 400;
                    color: $LightGrey;
                }
            }
        }
        .featureContainer {
                margin-top: 20px;
                .featureTile {
                    padding: 20px 20px;
                    transition: all 0.3s;
                    .title {
                        font-size: $Small;
                        font-variation-settings: "wght" 700;
                        color: $Black;
                        transition: all 0.3s;
                    }
                    .desc {
                        font-size: $xSmall;
                        font-weight: 400;
                        color: $LightGrey;
                    }
                    &:hover {
                        background-color: $White;
                        border-radius: 12px;
                        box-shadow: $Elevation-10;
                        .title {
                            font-variation-settings: "wght" 900;
                        }
                    }
                }
            }
    }

    .metaPeople {
        background: $Gradient;
        .metaPeopleContainer {
            min-height: 900px;
            padding: 120px 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .title {
                font-size: 2em;
                font-weight: 700;
                color: $Black;
                .light {
                    font-weight: 500;
                }
                .thick {
                    font-weight: 900;
                }
            }
            .desc {
                font-size: $Small;
                font-weight: 400;
                color: $LightGrey;
            }
            .highlight {
                font-size: 3em;
                font-weight: 900;
                color: $Black;
                margin-top: 40px;
            }
            .buttonsContainer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 60px;
                a {
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
        }
    }

    .portal {
        background: $LightGradient;
        .portalContainer {
            min-height: 900px;
            padding: 120px 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .title {
                font-size: 4em;
                font-weight: 800;
                color: $Black;
            }
            .desc {
                font-size: $Small;
                font-weight: 400;
                color: $LightGrey;
                margin-top: 4px;
            }
            .highlight {
                font-size: $Medium;
                font-weight: 800;
                color: $Black;
                margin-bottom: 20px;
            }
            .portalVideo {
                margin-top: 60px;
                border-radius: 20px;
            }
            .tags {
                font-size: $xSmall;
                font-weight: 400;
                color: $Grey;
                word-spacing: 5px;
                margin-top: 40px;
            }
            a {
                margin-top: 20px;
                background-color: $Orange;
                color: $White;
                padding: 8px 16px;
                text-decoration: none;
                border-radius: 4px;
                &:hover {
                    background-color: $Black;
                    counter-reset: $Orange;
                }
            }
        }
    }

    .reset {
        background-color: $ExtraDarkGrey;
        .resetContainer {
            padding: 120px 0px;
            .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .title {
                    font-size: $Large;
                    font-weight: 800;
                    color: $White;
                }
                .desc {
                    font-size: $xSmall;
                    color: $LightGrey;
                    margin-top: 12px;
                }
                .buttonsContainer {
                    margin-top: 20px;
                    .address {
                        font-weight: 200;
                        letter-spacing: 2px;
                        margin-left: 10px;
                    }
                    a {
                        &:last-child {
                            margin-left: 10px;
                        }
                    }
                }
            }
            .marketDataContainer {
                margin-top: 40px;
            }
            .row > [class*=" col-"] {
                margin-top: 0;
              }
            .tileContainer {
                background-color: $DarkGrey;
                padding: 24px 24px;
                border-radius: 12px;
                .title {
                    font-size: $Medium;
                    font-weight: 700;
                    color: $White;
                }
                .dataContainer{
                    margin-top: 12px;
                    width: 100%;
                    .dataTile {
                        margin-top: 10px;
                    }
                }
                .dataTile {
                    background-color: $Grey;
                    padding: 20px 20px;
                    border-radius: 12px;
                    width: 100%;
                    box-shadow: $Elevation-10;
                    .label {
                        font-size: $xSmall;
                        font-weight: 300;
                        color: $LightGrey;
                        margin-bottom: 8px;
                    }
                    .value {
                        font-size: $Small;
                        font-weight: 400;
                        color: $White;
                    }
                    .change {
                        font-size: $xxSmall;
                        padding: 2px 4px;
                        border-radius: 2px;
                        font-weight: 600;
                        margin-left: 8px;
                        &.positive {
                            background-color: $Green;
                        }
                        &.negative {
                            background-color: $Orange;
                        }
                    }
                    .valueContainer {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .home {
        .hero {
            min-height: 100vh;
            .bgVideo {
                min-height: 100vh;
            }
            .overlay {
               min-height: 100%;
                .heroContainer {
                    padding: 12px;
                    .highlightContainer {     
                        .title {
                            font-size: 3rem;
                        }
                        .desc {
                            font-size: 1em;
                            font-weight: 500;
                        }
                    }
                    .featureContainer {
                        .featureTile {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }


        .odyssey {
            padding: 12px 12px;
            .odysseyContainer {
                min-height: 900px;
                .content {
                    min-height: 100%;
                    .title {
                        font-size: 5em;
                        font-weight: 900;
                    }
                    .desc {
                        font-size: $Small;
                        font-weight: 500;
                    }
                }
            }
            .featureContainer {
                    margin-top: 100px;
                }
        }

        .metaPeople {
            .metaPeopleContainer {
                text-align: center;
                min-height: 700px;
                padding: 12px 12px;
                .title {
                    font-size: 2em;
                }
                .highlight {
                    font-size: 2em;
                }
                .buttonsContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-top: 60px;
                    a {
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                }
            }
        }

        .portal {
            .portalContainer {
                text-align: center;
                min-height: 800px;
                padding: 12px 12px;
                video {
                    width: 100%;
                    height: auto;
                }
                .title {
                    font-size: 4em;
                }
                .desc {
                    font-size: $Small;
                }
                .highlight {
                    font-size: $Medium;
                }
            }
        }

        .reset {
            background-color: $ExtraDarkGrey;
            .resetContainer {
                padding: 60px 12px;
                text-align: center;
                .content {
                    .buttonsContainer {
                        margin-top: 20px;
                        margin-bottom: 20px;
                        a {
                            &:last-child {
                                margin-top: 10px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
                .marketDataContainer {
                    margin-top: 20px;
                }
                .tileContainer {
                    .dataTile {
                        margin-top: 10px;
                        .valueContainer {
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}