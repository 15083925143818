@import '../variables.scss';

.footer {
    a {
        color: $White;
        text-decoration: none;
        &:hover {
            color: $White;
        }
    }
    background: $Black;
    .footerContainer {
        padding-top: 50px;
        padding-bottom: 50px; 
        .footerTop {
            padding-bottom: 25px;
            .linksContainer {
                color: $White;
                font-size: $xSmall;
                font-weight: 400;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                grid-gap: 12px; //safari <14 support
                gap: 12px;
                .social {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    grid-gap: 12px; //safari <14 support
                    gap: 12px;
                    .item {
                        font-size: $Medium;
                        &:hover {
                            color: $White;
                            cursor: pointer;
                        }
                    }
                }
            }
            .buttonContainer {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                height: 100%;
            }
        }
        .footerBottom {
            border-top: 0.5px solid $Black;
            padding-top: 25px;
            color: $LightGrey;
            font-size: $xSmall;
            a {
                color: $LightGrey;
                &:hover {
                    color: $Orange;
                }
            }
            .website {
                text-align: right;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .footer {
        .footerContainer {
            .logoContainer {
                text-align: center;
            }
            .linksContainer {
                flex-direction: column !important;
                margin-top: 10px;
            }
            .buttonContainer {
                justify-content: center !important;
                margin-top: 10px;
            }
            .footerBottom {
                .website {
                    text-align: center;
                }
                .copyright {
                    text-align: center;
                }
            }
        }
    }
}