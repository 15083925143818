@import '../variables.scss';

.header {
    background-color: $ContentLayerBg;
    backdrop-filter: $ContentLayerFilter;
    box-shadow: $Elevation-10;
    color: $White;
    padding: 12px 12px;
    border-radius: 12px;
    width: 100%;
    
    .headerContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    
        .menuItemContainer {
            align-items: center;
            .buttonItem {
                margin-left: 10px;
            }
            .menuItem {
                padding: 5px 10px;
                text-decoration: none;
                color: $LightGrey;
                font-size: $xSmall;
                font-weight: 400;
                opacity: 0.75;
                text-align: center;
                &.active {
                    font-weight: 600;
                    opacity: 1.0;
                    color: $Black;
                }
                &:hover {
                    opacity: 1.0;
                    color: $Black;
                }
                .highlight {
                    color: $Orange;
                    font-weight: 500;
                    font-size: $Small;
                    background-color: $White;
                    border: 1px solid $Orange;
                    padding: 8px 12px;
                    border-radius: 4px;
                }
            }
            .launchButton {
                background-color: $Black;
                color: $White;
                border-radius: 4px;
                padding: 5px 10px;
                font-size: $xSmall;
                font-weight: 600;
                text-decoration: none;
                transition: all 0.5s ease-in-out;
                &:hover {
                    cursor: pointer;
                    background: $Gradient;
                    color: $Black;
                }
            }
            .social {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                border-left: 1px solid $ExtraLightGrey;
                margin-left: 12px;
                gap: 12px;
                padding-left: 10px;
                .item {
                    color: $Black;
                    font-size: $Small;
                    &:hover {
                        color: $Blue;
                        cursor: pointer;
                    }
                }
            }
            .connectedAddress {
                font-size: $xSmall;
                color: $LightGrey;
                font-weight: 400;
                text-align: right;
                .address {
                    font-weight: 600;
                    color: $Black;
                    letter-spacing: 0.1em;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .header {
        .headerContainer {
            .menuItemContainer {
                .social {
                    justify-content: center;
                    border: none;
                    padding-left: 0;
                    border-top: 1px solid $LightGrey;
                    padding-top: 20px;
                    margin-top: 20px;
                }
            }
        }
    }
}