@import './variables.scss';
@import "~bootstrap/scss/bootstrap.scss";

$theme-colors: (
    "black": $Black,
    "extraDarkGrey": $ExtraDarkGrey,
    "darkGrey": $DarkGrey,
    "grey": $Grey,
    "white": $White,
    "orange": $Orange
);

body {
    background-color: $White;
}

.btn {
    font-weight: 500;
    font-size: $xSmall;
    border-radius: 4px;
}

.btn-black {
    background-color: $Black;
    color: $White;
    &:hover {
        background-color: $Black;
        color: $Orange;
    }
}

.btn-blue {
    background-color: $Blue;
    color: $White;
    &:hover {
        background-color: $Blue;
        color: $White;
    }
}

.btn-green {
    background-color: $Green;
    color: $White;
    &:hover {
        background-color: $Green;
        color: $White;
    }
}

.btn-white {
    background-color: $White;
    color: $Black;
    &:hover {
        background: $Gradient;
        color: $White;
    }
}

.btn-orange {
    background-color: $Orange;
    color: $Black;
    &:hover {
        background: $Black;
        color: $White;
    }
}

.btn-outline-white {
    background-color: transparent;
    color: $White;
    border-color: $White;
    &:hover {
        background: $Gradient;
        color: $White;
    }
}


.btn-outline-grey {
    background-color: transparent;
    color: $Grey;
    border-color: $Grey;
    &:hover {
        background: $Gradient;
        color: $Black;
    }
}

.row > * {
    margin-top: 0;
}

.modal {
    width: 100%;
    .modal-dialog {
        max-width: 1200px;
        .modal-content {
            border-radius: 0px;
            background-color: transparent;
            .modal-body {
                padding: 0;
                height: 800px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                /* From https://css.glass */
                background-color: $White;
                .previewImage{
                    width: 800px;
                    height: 100%;
                }
                .attributesContainer{
                    width: 400px;
                    padding: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    height: 800px;
                    .dataContainer {
                     width: 100%;
                     display: flex;
                     flex-direction: column;
                     justify-content: flex-start;
                     align-items: center;
                        .header {
                            padding-bottom: 16px;
                            border-bottom: 1px solid $LightGrey;
                            width: 80%;
                            .title {
                                font-size: $Large;
                                font-weight: 800;
                            }
                            .name {
                                font-size: $xxSmall;
                                text-transform: uppercase;
                                letter-spacing: 6px;
                            }
                        }
                        .content {
                            padding-top: 10px;
                            width: 80%;
                            .item {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                width: 100%;
                                flex-direction: row;
                                border-bottom: 0.5px solid $LightGrey;
                                padding: 5px 0px 5px 0px;
                                font-size: $xSmall;
                            }
                        }   
                        .price {
                            padding-top: 20px;
                            padding-bottom: 20px;
                            text-align: center;
                            border-bottom: 1px solid $LightGrey;
                            width: 80%;
                            .value {
                                font-size: $xLarge;
                                color: $Blue;
                            }
                            .label {
                                font-size: $xSmall;
                                color: $Black;
                            }
                        }
                        .message {
                            text-align: center;
                            width: 80%;
                            font-size: $xxSmall;
                            color: $Grey;
                            margin-top: 20px;
                            .value {
                                color: $Black;
                                font-weight: 700;
                            }
                        } 
                    }
                    .footerContainer {
                        width: 100%;;
                        padding: 10px;
                        text-align: center;
                        padding-top: 20px;
                        .blinkMe {
                            animation: blinker 1s linear infinite;
                          }
                          
                          @keyframes blinker {
                            50% {
                              opacity: 0;
                            }
                          }
                        .warning {
                            background-color: navajowhite;
                            padding: 20px;
                        }
                        .inprogress {
                            padding: 20px;
                            .message {
                                color:  $Blue;
                                padding: 5px 5px;
                                border-radius: 2px;
                                font-size: $Medium;
                            }
                            a {
                                font-size: $xSmall;
                                color: $Grey;;
                            }
                        }
                        .done {
                            padding: 20px;
                            .message {
                                color:  $White;
                                padding: 5px 5px;
                                border-radius: 5px;
                                font-size: $Small;
                                border-radius: 4px;;
                                background-color: $Green;
                            }
                            a {
                                font-size: $xSmall;
                                color: $Grey;;
                            }
                        }
                    }
                }
            }
        }
        &.usernameModal {            
            .modal-content {
                background-image: url('./assets/images/aboutbg.jpg');
                background-position: center;
                background-size: cover;
                border-radius: 12px;
                .modal-body {
                    background-color: $BaseLayerBg;
                    backdrop-filter: $BaseLayerFilter;
                    .content {
                        .register {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            .title {
                                font-size: $xLarge;
                                font-weight: 400;
                                color: $Black;
                            }
                            .desc {
                                font-size: $xSmall;
                                color: $LightGrey;
                                font-weight: 400;
                            }
                            .inputContainer {
                                margin-top: 24px;
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                .inputText {
                                    padding: 12px 24px;
                                    border-radius: 4px;
                                    border: 1px solid $ExtraLightGrey;
                                    font-size: $xSmall;
                                    text-align: center;
                                    width: 80%;
                                    margin-right: 12px;
                                    text-transform: uppercase;
                                    letter-spacing: 0.8em;
                                }
                                .button {
                                    height: 48px;
                                    border-radius: 4px;
                                    background-color: $Black;
                                    color: $White;
                                    border: 0;
                                    border-color: $Black;
                                    padding: 12px 24px;
                                    &:hover {
                                        color: $Orange;
                                    }
                                }
                                .disabledButton {
                                    padding: 12px 24px;
                                    border-radius: 4px;
                                    background-color: $ExtraLightGrey;
                                    height: 48px;
                                    color: $LightGrey;
                                    border: 0;
                                    border-color: $ExtraLightGrey;
                                }
                            }

                            .validtion {
                                display: flex;
                                margin-top: 40px;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                .item {
                                    border-radius: 4px;
                                    font-size: $xSmall;
                                    color: $White;
                                    background-color: $Grey;
                                    padding: 4px 12px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    .warning {
                                        color: $Orange;
                                    }
                                    .valid {
                                        color: $Green;
                                    }
                                    &:first-child {
                                        margin-right: 20px;
                                    }
                                }
                            }

                            .registered {
                                margin-top: 20px;
                                font-size: $xSmall;
                                color: $Black;
                                background-color: $Orange;
                                padding: 12px 24px;
                                border-radius: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal-backdrop
        {
            opacity:0.85 !important;
            background-color: black;
        }


@media only screen and (max-width: 991px) {
    .modal {
        width: 100%;
        padding: 30px 20px;
        .modal-dialog {
            .modal-content {
                border-radius: 0px;
                .modal-body {
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .previewImage{
                        width: 300px;
                        height: 300px;
                    }
                    .attributesContainer{
                        width: 100%;
                        background-color: white;
                        padding: 20px;
                        height: auto;
                        .item {
                            padding: 2px 0px 2px 0px;
                        }
                    }
                }
            }
        }
    }
}

