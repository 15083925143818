@import '../variables.scss';

.token {
    .heroMini {
        background-image: url('./../assets/images/tokenbg.jpeg');
        background-size: cover;
        .overlay {
            background-color: rgba(0, 0, 0, 0.4);
            padding: 60px 0px;
            .content {
                margin-top: 30%;
                color: $White;
                .title {
                    font-size: $xxLarge;
                    font-weight: 900;
                }
                .desc {
                    font-size: $Medium;
                    font-weight: 600;
                    .light {
                        font-weight: 400;
                    }
                    .thick {
                        font-weight: 800;
                    }
                }
                .tileContainer {
                    margin-top: 20px;
                    .dataTile {
                        background-color: $BaseLayerBg;
                        backdrop-filter: $BaseLayerFilter;
                        box-shadow: $Elevation-10;
                        padding: 20px 20px;
                        height: 100%;
                        border-radius: 12px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        .label {
                            font-size: $xSmall;
                            color: $Grey;
                            font-weight: 400;
                        }
                        .valueContainer {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: flex-start;
                        }
                        .value {
                            color: $Black;
                            font-weight: 500;
                        }
                        .change {
                            font-weight: 400;
                            font-size: $xSmall;
                            padding: 2px 4px;
                            border-radius: 2px;
                            color: $Black;
                            margin-left: 10px;
                            &.positive {
                                background-color: $Green;
                            }
                            &.negative {
                                background-color: $Orange;
                            }
                        }
                    }
                }
            }
        }
    }

    .tokenDistribution {
        background: $LightGradient;
        padding: 120px 0px;
        .tokenDistributionContainer {
            text-align: center;
            .title {
                font-size: $xLarge;
                font-weight: 900;
                color: $Black;
                .light {
                    font-weight: 400;
                }
            }
            .chartContainer {
                height: 700px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background-image: url('./../assets/images/chartbg.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                
                .chartInnerContainer {
                    border-radius: 50%;
                    background-color: rgba(255, 255, 255, 0.1);
                    backdrop-filter: blur(20px);
                    height: 400px;
                    width: 400px;
                    .textContainer {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        .label {
                            font-size: $Small;
                            color: $Grey;
                            font-weight: 400;
                        }
                        .value {
                            font-size: $Medium;
                            color: $Black;
                            font-weight: 700;
                        }
                    }
                }
            }
            .legendContainer {
                padding-left: 20px;
                .item {
                    margin-top: 20px;
                    text-align: left;
                    .label {
                        font-size: $xSmall;
                        color: $Black;
                        font-weight: 500;
                        .thick {
                            font-weight: 700;
                        }
                    }
                    .value {
                        font-size: $xSmall;
                        font-weight: 400;
                        color: $Grey;
                    }
                    &:first-child {
                        border-left: 40px solid $LightBlue;
                    }
                    &:nth-child(2) {
                        border-left: 40px solid $Blue;
                    }
                    &:nth-child(3) {
                        border-left: 40px solid $DarkBlue;
                    }
                    &:nth-child(4) {
                        border-left: 40px solid $ExtraDarkBlue;
                    }
                    &:nth-child(5) {
                        border-left: 40px solid #142546;
                    }
                }
            }
        }
    }

    .links{
        padding: 120px 0px;
        background: $DarkGrey;
        .linksContainer {
            background-image: url('./../assets/images/tokenbg.jpeg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 20px;
            padding: 0;
            .overlay {
                background-color: rgba(0, 0, 0, 0.2);
                padding: 20px 20px;
                border-radius: 20px;
                .tile {
                    background-color: $BaseLayerBg;
                    backdrop-filter: $BaseLayerFilter;
                    box-shadow: $Elevation-10;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 20px 20px;
                    border-radius: 12px;
                    text-decoration: none;
                    .title {
                        color: $Black;
                        font-size: $Small;
                        font-weight: 700;
                        margin-left: 10px;
                    }
                }
            }
        }
        .logoContainer {
            padding: 60px 60px 0px 60px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 991px) {
    .token {
        .heroMini {
            //min-height: 100vh;
            .overlay {
                padding: 12px 12px;
                min-height: 100%;
                .heroMiniContainer {
                    min-height: 100vh;
                    .content {
                        .tileContainer {
                            margin-top: 40px;
                            .dataTile {
                                margin-bottom: 10px;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    
        .tokenDistribution {
            background: $LightGradient;
            padding: 60px 12px;
            .tokenDistributionContainer {
                .chartContainer {
                    height: auto;
                }
                .legendContainer {
                    margin-left: 20px;
                }
            }
        }
    
        .links{
            padding: 60px 12px;
            .linksContainer {
                .overlay {
                    .tile {
                        margin-top: 10px;
                    }
                }
            }
            .logoContainer {
                img {
                    margin-top: 20px;
                }
            }
        }
    }
}