//color template
$White: #FFFFFF;
$ExtraLightGrey: #DADADA;
$LightGrey: #737373;
$Grey: #1C1C1C;
$DarkGrey: #131313;
$ExtraDarkGrey: #0D0D0D;
$Black: #000000;
$Orange: #FF6F61;
$Green: #5FF2A3;
$LightBlue: #31D7FB;
$Blue: #1B96EE;
$DarkBlue: #1E52B2;
$ExtraDarkBlue: #153267;
$LightGradient: radial-gradient(128.23% 128.23% at 9.34% 11.56%, rgba(203, 185, 206, 0.08) 0%, rgba(185, 184, 221, 0.1) 26.56%, rgba(147, 181, 236, 0.06) 55.73%, rgba(171, 225, 235, 0.2) 99.99%, rgba(171, 225, 235, 0.09) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
$MediumGradient: radial-gradient(128.23% 128.23% at 9.34% 11.56%, rgba(203, 185, 206, 0.2) 0%, rgba(185, 184, 221, 0.16) 26.56%, rgba(147, 181, 236, 0.18) 55.73%, rgba(171, 225, 235, 0.4) 99.99%, rgba(171, 225, 235, 0.09) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
$Gradient:  radial-gradient(128.23% 128.23% at 9.34% 11.56%, rgba(203, 185, 206, 0.46) 0%, rgba(185, 184, 221, 0.28) 26.56%, rgba(147, 181, 236, 0.37) 55.73%, rgba(171, 225, 235, 0.75) 99.99%, rgba(171, 225, 235, 0.09) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
$ContentLayerBg: rgba(255, 255, 255, 0.90);
$ContentLayerFilter: blur(240px);
$CommandLayerBg: rgba(255, 255, 255, 0.70);
$CommandLayerFilter: blur(240px);
$BaseLayerBg: rgba(255, 255, 255, 0.50);
$BaseLayerFilter: blur(120px);
$Elevation-10: 0px 10px 10px 5px rgba(0, 0, 0, 0.05);
$Elevation-5: 0px 5px 5px 3px rgba(0, 0, 0, 0.03);

//Font sizes
$xxxSmall: 0.5em;
$xxSmall: 0.75em;
$xSmall: 0.85em;
$Small: 1em;
$Medium: 1.5em;
$Large: 2em;
$xLarge: 2.5em;
$xxLarge: 3em;
$xxxLarge: 4em;