@import '../variables.scss';

.community {
    .heroMini {
        background-image: url('./../assets/images/communitybg.jpeg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .overlay {
            background-color: rgba(0, 0, 0, 0.4);
            padding: 60px 0px;
            .content {
                margin-top: 30%;
                color: $White;
                .title {
                    font-size: $xxLarge;
                    font-weight: 900;
                }
                .desc {
                    font-size: $Medium;
                    font-weight: 600;
                    .light {
                        font-weight: 400;
                    }
                    .thick {
                        font-weight: 800;
                    }
                }
            }
        }
    }

    .links {
        background-color: $DarkGrey;
        .linksContainer {
            padding: 120px 0px;
            .title {
                font-size: 3em;
                color: $ExtraLightGrey;
                font-weight: 400;
                .light {
                    font-weight: 200;
                    color: $White;
                }
                .medium {
                    font-weight: 500;
                    color: $White;
                }
                .thick {
                    font-weight: 700;
                    color: $White;
                }
            }
            .tileContainer {
                display: flex;
                flex-wrap: wrap;
                margin-top: 40px;
                .item {
                    background-color: $Grey;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 20px 20px;
                    border-radius: 12px;
                    margin-right: 20px;
                }
            }
        }
    }
    
    .tweets {
        background: $LightGradient;
        .tweetsContainer {
            padding: 120px 20px;
            text-align: center;
            .title {
                font-size: $xLarge;
                font-weight: 800;
                color: $Black;
            }
            .desc {
                font-size: $Small;
                font-weight: 400;
                color: $Grey;
                margin-bottom: 40px;
            }
            .tile {
                padding: 12px;
            }
            .socialTiles {
                margin-bottom: 40px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 24px;
                .item {
                    background-color: $White;
                    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
                    padding: 40px;
                    border-radius: 8px;
                    height: 140px;
                    width: 140px;
                    margin-left: 6px;
                    margin-right: 6px;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .community {
        .heroMini {
            .overlay {
                background-color: rgba(0, 0, 0, 0.4);
                padding: 12px 12px;
                .heroMiniContainer {
                    height: 400px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
        }
    
        .links {
            .linksContainer {
                padding: 60px 20px;
                .title {
                    font-size: 2em;
                }
                .tileContainer {
                    .item {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}